<template>
  <div class="row">
    <div class="col-md-12">
      <card class="stacked-form">
        <form @submit.prevent="submit">
          <!-- form -->
          <div class="row">
            <div class="col-12">
              <base-input label="Título da sugestão" v-model="form.name"></base-input>
            </div>

            <div class="col-6">
              <label>Unidade de Medida</label>
              <select v-model="form.unity_id" class="form-control custom-select">
                <option v-for="unity in unities" :key="unity.id" :value="unity.id">
                  {{ unity.unity }}
                </option>
              </select>
            </div>

            <div class="col-12">
              <label class="mr-3 mb-0">Status</label>

              <el-radio-group v-if="form.status !== null" v-model="form.status">
                <el-radio class="mb-0" :label="1">Ativo</el-radio>
                <el-radio class="mb-0" :label="0">Inativo</el-radio>
              </el-radio-group>
            </div>

            <!-- botoes -->
            <div class="col-12 mt-3">
              <base-button @click.prevent.stop="goBack()" type="danger">Cancelar</base-button>
              <base-button native-type="submit" type="success" class="ml-3">Salvar</base-button>
            </div>
          </div>
        </form>
      </card>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import swal from 'sweetalert2'
import vSelect from 'vue-select'
Vue.component('v-select', vSelect)
export default {
  data: () => ({
    form: {
      status: 0
    },
    unities: []
  }),
  methods: {
    async getData () {
      const id = this.$route.params.id || false
      if (id) {
        this.form = (await this.$http.get(`categories/${id}`)).data
      }
    },
    async submit () {
      if (this.validate()) {
        const id = this.$route.params.id || false
        let status
        try {
          if (id) {
            status = (await this.$http.put(`item-suggestions/${id}`, this.form)).status
          }
          if (!id) {
            this.form.user_id = 1
            status = (await this.$http.post('item-suggestions', this.form)).status
          }
        } catch (e) {
          status = e.response.status
        }

        if (status === 200) {
          swal.fire({
            title: 'Salvo!',
            text: `"${this.form.name}" salvo com sucesso.`,
            showConfirmButton: false,
            timer: 2000
          })
          this.goBack()
        } else {
          swal.fire({
            title: 'Erro!',
            text: 'Ocorreu um erro, tente novamente mais tarde.',
            denyButtonText: 'Ok',
            showDenyButton: true,
            showConfirmButton: false
          })
        }
      } else {
        swal({
          title: 'Erro!',
          text: 'Por favor, preencha todos os campos obrigatórios.',
          denyButtonText: 'Ok',
          showDenyButton: true,
          showConfirmButton: false
        })
      }
    },
    goBack () {
      window.history.go(-1)
    },
    validate () {
      const form = this.form
      if (!form.name || !form.name.trim()) return false
      return true
    },
    async getTypes () {
      const url = 'types-of-categories'
      this.types = (await this.$http.get(url)).data.data
    },
    async getUnities () {
      const url = 'unities'
      const { data } = await this.$http.get(url)
      this.unities = data.data
    }
  },
  mounted () {
    this.getData()
    this.getTypes()
    this.getUnities()
  }
}
</script>
